.App {
  text-align: center;
  background-color: #171717;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  margin-top: 56px;
}

.App__Home .App-header {
  flex-grow: 1;

  margin-top: 0;
}

.App-footer {
  min-height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.App-footer .vertical-divider {
  margin-right: 16px;
  margin-left: 16px;
}

.App-footer a {
  color: #4B4B4B;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

.content {
  flex-grow: 1;

  margin: 0 8vw;
  padding-bottom: 32px;
  color: #636363;
  text-align: left;
}

@media (min-width: 640px) {
  .content {
    margin: 0 16vw;
  }
}

@media (min-width: 1024px) {
  .content {
    margin: 0 24vw;
  }
}

.content h2 {
  margin-top: 62px;
  margin-bottom: 16px;
}

.content p {
  margin-bottom: 16px;
}

.App__Disclaimer .content {
  font-size: 14px;
}

.App__Disclaimer .post-disclaimer {
  margin-bottom: 16px;
  display: block;
}


.threecrows__logo {
  width: 92px;
  pointer-events: none;
}

.threecrows__text {
  width: 111px;
  margin-top: 25.5px;
}

.threecro_ws__text {
  width: 69.49px;
  margin-top: 16.2px
}


._threecrows-link {
  display: inline;
  margin-top: 0;

  color: #ACACAC;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
}

._threecrows-link ._threecrows-link__icon {
  width: 14.29px;
  margin-bottom: 0;
  display: inline-block;
  margin-right: 10.7px;
}

._threecrows-link ._threecrows-link__icon path {
  fill: #4B4B4B;
}

._threecrows-link ._threecrows-link__icon circle {
  fill: #4B4B4B;
}

.App__Home ._threecrows-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 56px;
}


.App__Home ._threecrows-link ._threecrows-link__icon {
  width: 20.29px;
  margin-bottom: 8px;
  margin-right: 0;
  display: block;
}

.App__Home ._threecrows-link ._threecrows-link__icon path {
  fill: #fff;
}

.App__Home ._threecrows-link ._threecrows-link__icon circle {
  fill: #fff;
}